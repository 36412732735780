exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/blogList.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-01-04-como-um-robo-pode-ser-um-diferencial-para-sua-empresa-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/2023-01-04-como-um-robô-pode-ser-um-diferencial-para-sua-empresa.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-01-04-como-um-robo-pode-ser-um-diferencial-para-sua-empresa-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-01-11-boas-praticas-para-implantacao-de-rpa-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/2023-01-11-boas-práticas-para-implantação-de-rpa.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-01-11-boas-praticas-para-implantacao-de-rpa-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-01-22-como-fazer-um-robo-que-faz-pesquisa-no-google-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/2023-01-22-como-fazer-um-robô-que-faz-pesquisa-no-google.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-01-22-como-fazer-um-robo-que-faz-pesquisa-no-google-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-02-07-o-que-e-roi-e-sua-importancia-em-um-projeto-de-rpa-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/2023-02-07-o-que-é-roi-e-sua-importância-em-um-projeto-de-rpa.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-02-07-o-que-e-roi-e-sua-importancia-em-um-projeto-de-rpa-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-02-15-quais-sao-os-principais-beneficios-do-rpa-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/2023-02-15-quais-sao-os-principais-beneficios-do-rpa.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-02-15-quais-sao-os-principais-beneficios-do-rpa-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-02-23-quais-problemas-o-rpa-pode-resolver-em-er-ps-do-setor-varejista-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/2023-02-23-quais-problemas-o-RPA-pode-resolver-em-ERPs-do-setor-varejista.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-02-23-quais-problemas-o-rpa-pode-resolver-em-er-ps-do-setor-varejista-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-02-30-rpas-no-agranegocio-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/2023-02-30-rpas-no-agranegocio.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-02-30-rpas-no-agranegocio-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-03-07-empresas-do-ramo-financeiro-e-o-rpa-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/2023-03-07-empresas-do-ramo-financeiro-e-o-rpa.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-03-07-empresas-do-ramo-financeiro-e-o-rpa-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-03-13-rpa-na-saude-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/2023-03-13-rpa-na-saude.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-03-13-rpa-na-saude-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-03-25-rpa-em-numeros-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/2023-03-25-rpa-em-numeros.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-03-25-rpa-em-numeros-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-04-01-o-rh-pode-performar-ainda-melhor-com-rpa-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/2023-04-01-o-rh-pode-performar-ainda-melhor-com-rpa.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-04-01-o-rh-pode-performar-ainda-melhor-com-rpa-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-04-11-disrupcao-do-rpa-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/2023-04-11-disrupcao-do-rpa.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-04-11-disrupcao-do-rpa-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-04-17-lowcode-seus-beneficios-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/2023-04-17-lowcode-seus-beneficios.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-04-17-lowcode-seus-beneficios-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-05-12-saiba-como-e-facil-desenvolver-com-rpa-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/2023-05-12-saiba-como-e-facil-desenvolver-com-rpa.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-05-12-saiba-como-e-facil-desenvolver-com-rpa-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-05-28-boa-praticas-ao-implementar-rpa-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/2023-05-28-boa-praticas-ao-implementar-rpa.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-05-28-boa-praticas-ao-implementar-rpa-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-06-03-rpa-bpm-ai-concorrem-ou-colaboram-entre-si-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/2023-06-03-rpa-bpm-ai-concorrem-ou-colaboram-entre-si.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-06-03-rpa-bpm-ai-concorrem-ou-colaboram-entre-si-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-06-15-como-escolher-o-rpa-certo-para-minha-empresa-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/2023-06-15-como-escolher-o-rpa-certo-para-minha-empresa.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-06-15-como-escolher-o-rpa-certo-para-minha-empresa-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-07-02-rpa-combinado-ao-ml-e-analise-de-dados-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/2023-07-02-rpa-combinado-ao-ml-e-analise-de-dados.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-07-02-rpa-combinado-ao-ml-e-analise-de-dados-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-08-29-tecnologia-agnostica-evitando-o-aprisionamento-tecnologico-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/2023-08-29-tecnologia-agnóstica-evitando-o-aprisionamento-tecnológico.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-08-29-tecnologia-agnostica-evitando-o-aprisionamento-tecnologico-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-09-01-tres-temas-que-estao-sempre-a-mesa-quando-assunto-eh-rpa-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/2023-09-01-tres-temas-que-estao-sempre-a-mesa-quando-assunto-eh-rpa.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-09-01-tres-temas-que-estao-sempre-a-mesa-quando-assunto-eh-rpa-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-09-08-melhores-pratica-na-automoacao-de-fluxos-complexos-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/2023-09-08-melhores-pratica-na-automoacao-de-fluxos-complexos.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-09-08-melhores-pratica-na-automoacao-de-fluxos-complexos-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-09-08-politicas-e-praticas-de-governanca-adequadas-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/2023-09-08-políticas-e-praticas-de-governanca-adequadas.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-09-08-politicas-e-praticas-de-governanca-adequadas-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-09-08-seguranca-na-automacao-de-processos-digitais-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/2023-09-08-seguranca-na-automacao-de-processos-digitais.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2023-09-08-seguranca-na-automacao-de-processos-digitais-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-2024-01-26-utilizacao-de-tecnologia-de-rpa-para-area-da-saude-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/buildhome/repo/content/blog/2024-01-26-utilizacao-de-tecnologia-de-rpa-para-area-da-saude.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-2024-01-26-utilizacao-de-tecnologia-de-rpa-para-area-da-saude-mdx" */)
}

